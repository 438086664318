@import 'intl-tel-input/build/css/intlTelInput.css';
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  height: 100%;
}

@media (max-width: 1024px) {
  html {
    font-size: 80%;
  }
}

@media (max-width: 772px) {
  html {
    font-size: 60%;
  }
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
} /* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #eeeef6!important;
}

.info-button {
  box-shadow: none!important;
  margin-left: 4px;
}

input:focus, textarea:focus, select:focus {
  border: 1px solid #8f68e3!important;
}
.navigation-bar {
  font-family: "poppins";
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: #5d59a8;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 20px;
}
.navigation-bar ul{
  justify-content: center;
  list-style: none;
  margin: 0;
  gap: 40px;
  color: #fff;
}
.navigation-bar ul li{
  list-style: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
}
.navigation-bar ul li:hover{
  text-decoration: underline;
}
.custom-card {
  margin: 50px 0!important;
  padding: 0 0 50px!important;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.backg-gradient {
  min-height: calc(100vh );
  /* background: linear-gradient(to right, #5d59a8, #edf7ff, #5d59a89c); */
  background-color: #5d59a8;
}
.iti--allow-dropdown {
  width: 100%;
}
input[type="tel"] {
  padding: 14px;
  width: 100%;
  border-radius: 5px; 
  border: 1px solid #9e9e9e;
}

.back-button-container {
  text-align: left;
  padding-top: 1rem;
  margin: 0;
}

.back-button {
  background: #fff;
  color: #5d59a8;
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem; /* 14px */
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.back-button span {
  font-size: 1.6rem; /* 16px */
  margin-right: 10px;
}

.back-button:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.back-button:active {
  transform: scale(0.98);
}

.back-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(76, 131, 255, 0.5);
}

button:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transform: scale(1.05);
  transition: all 0.2s;
}

a:hover {
  text-decoration: underline!important;
}